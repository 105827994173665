import { ReactNode, useEffect, useState } from "react";
import "./Prompt.scss";
import { ReactComponent as Close } from "./close.svg";
// import { ReactComponent as Sketch1 } from "assets/backgrounds/sketch1.svg";
// import { ReactComponent as Sketch2 } from "assets/backgrounds/sketch2.svg";
// import { ReactComponent as Sketch3 } from "assets/backgrounds/sketch3.svg";
// import { ReactComponent as Sketch4 } from "assets/backgrounds/sketch4.svg";
// import { ReactComponent as Sketch5 } from "assets/backgrounds/sketch5.svg";
// import { ReactComponent as Sketch6 } from "assets/backgrounds/sketch6.svg";
import { ReactComponent as Sketch7 } from "assets/backgrounds/sketch7.svg";
import Logo from "components/Logo";

interface PromptProps {
  children: ReactNode;
  className?: string;
  title?: string;
  onLogoClick?: () => void;
  onClose?: () => void;
}
function Prompt({
  className = "",
  children,
  title = "",
  onLogoClick,
  onClose = () => {},
}: PromptProps) {
  const [entering, setEntering] = useState(false);
  const [sketch] = useState(Math.floor(Math.random() * 6));
  const close = () => {
    setEntering(false);
    setTimeout(() => {
      onClose();
    }, 200);
  };

  useEffect(() => {
    setEntering(true);
  }, []);

  const handleClose = () => {
    close();
  };

  return (
    <div
      className={"Prompt " + className + " " + (entering ? " entering" : "")}
      onClick={handleClose}
    >
      <div className={"prompt-inner"} onClick={(e) => e.stopPropagation()}>
        <div className={"prompt-header"}>
          <div className={"prompt-title"}>
            <Logo onClick={onLogoClick} />
          </div>
          <button className={"cross"} onClick={handleClose}>
            <Close />
          </button>
        </div>
        <div className={"prompt-body"}>
          <div className={"prompt-body-inner"}>{children}</div>
          <div className={"sketch"}>
            <Sketch7 />
            {/*{sketch === 0 && <Sketch1 />}*/}
            {/*{sketch === 1 && <Sketch2 />}*/}
            {/*{sketch === 2 && <Sketch3 />}*/}
            {/*{sketch === 3 && <Sketch4 />}*/}
            {/*{sketch === 4 && <Sketch5 />}*/}
            {/*{sketch === 5 && <Sketch6 />}*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prompt;
